<template>
  <div class="files">
    <div class="files__background">
      <file-upload
        :accept="
          acceptMore ? acceptMore : '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx'
        "
        input-id="eventFilesBugs"
        class="file-uploader"
        post-action="/upload/post"
        :multiple="multiple"
        :drop="drop"
        :drop-directory="dropDirectory"
        v-model="files"
        ref="eventFilesBugs"
        name="eventFilesBugs"
      >
        <div class="uploader-icon">
          <p>Перетащите файл сюда или</p>
          <i class="icon-paper-clip-secondary"></i>
        </div>
      </file-upload>
      <ul class="files__list files__list_doc" v-if="files.length">
        <li class="files__info" v-for="(file, index) in documents" :key="index">
          <i class="icon-paper-clip-secondary"></i>
          <div class="files__name">
            {{ cutterText(file.name) }}
          </div>
          <div class="files__delete" @click="files.splice(index, 1)">
            <i class="icon-cross"></i>
          </div>
        </li>
      </ul>
      <ul class="files__list" v-if="files.length">
        <li
          class="files__item files-draggable"
          v-for="(img, index) in cover"
          :key="img.id"
          draggable="true"
        >
          <img class="files__img" :src="img.src" :alt="img.name" />
          <i class="icon-cross" @click="files.splice(index, 1)"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
const FileUpload = () => import("vue-upload-component");
import draggable from "vuedraggable";

export default {
  name: "FileUploaderBugs",
  mixins: [],
  props: [
    "multiple",
    "drop",
    "dropDirectory",
    "fileDrop",
    "deep",
    "lengthText",
    "showOnlyFiles",
    "editingFiles",
    "acceptMore"
  ],
  components: { IcoMoonWrap, draggable, FileUpload },
  data() {
    return {
      files: [],
      editFile: [],
      cover: [],
      documents: []
    };
  },
  mounted() {
    let self = this;
    setTimeout(() => {
      if (this.editingFiles) {
        this.editFile = this.editingFiles;
      }
    }, 0);
    this.$root.$on("getFilesFromUploader", function () {
      self.gettingAddFiles();
    });
    this.$root.$on("getEditFiles", function () {
      self.gettingEditFiles();
    });
    this.$root.$on("getFilesFromUploader", function () {
      self.gettingAddFiles();
    });
    this.$root.$on("pushFileToFilesBugs", (item) => {
      this.files.push(item);
    });
  },
  methods: {
    setExtensionIcon(file) {
      switch (file.extension ? file.extension : file.type) {
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return { name: "doc", path: 7 };
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return { name: "ppt", path: 7 };
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return { name: "xls", path: 7 };
        case "application/pdf":
          return { name: "pdf", path: 7 };
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
          return { name: "picture", path: 3 };
        case "video/mp4":
        case "audio/mpeg":
        case "video/quicktime":
        case "video/3gpp":
        case "video/x-ms-wmv":
        case "video/mpeg":
        case "video/x-flv":
        case "video/x-msvideo":
          return { name: "video-player", path: 11 };
        default:
          break;
      }
    },
    sizeFloor(size) {
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + "tb";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + "gb";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + "mb";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + "kb";
      } else {
        return size + "b";
      }
    },
    cutterText(text) {
      const extension = text.split(".");
      if (text.length <= this.lengthText) {
        return text;
      } else return text.substr(0, this.lengthText - 2) + "..." + extension[extension.length - 1];
    },
    gettingAddFiles() {
      this.$root.$emit("gettingFiles", this.files);
    },
    gettingEditFiles() {
      this.$root.$emit("gettingEditFiles", this.editFile, this.files);
    },
    getUrlFile() {
      this.cover = [];
      this.documents = [];
      this.files.forEach((item) => {
        if (item.type === "image/jpeg" || item.type === "image/jpg" || item.type === "image/png") {
          let reader = new FileReader();
          reader.readAsDataURL(item.file);
          reader.onload = () => {
            this.cover.push({
              src: reader.result,
              name: item.name,
              id: item.id
            });
          };
        } else {
          this.documents.push(item);
        }
      });
    }
  },
  computed: {},
  watch: {
    files() {
      this.$emit("changeFilesFromUploader", this.files);
      this.getUrlFile();
    },
    editFile() {
      this.$emit("changeEditFilesFromUploader", this.editFile);
    }
  },
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 16px;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #eae8f0;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: $action-primary-accent;
  }
}

.files {
  width: 100%;
  position: relative;
  height: auto;

  &__background {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap;

    .file-uploader {
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed $border-disabled;
      border-radius: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 26px;
      z-index: 100;
      color: $text-subdued;
      white-space: nowrap;

      @media (max-height: $sm) {
        height: 50px;
      }

      .uploader-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          margin: 0 0 0 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          width: 26px;
          border-radius: 50%;
          background: #fafbfc;
        }
      }

      &:hover {
        color: $text-highlight;
      }

      &:active {
        color: $text-highlight;
      }

      /deep/ {
        img {
          margin-top: 14px;
        }
        .attach-file {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          transition: color 0.3s ease;
        }
        label {
          cursor: pointer;
        }
      }
    }
  }

  &__list {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: scroll;
    max-height: 150px;

    @media (max-height: $xxxl) {
      max-height: 120px;
    }

    @media (max-height: $sm) {
      max-height: 80px;
    }

    @media (max-height: 320px) {
      max-height: 40px;
    }

    @media screen and (max-width: 850px) and (orientation: landscape) {
      max-height: max-content;
      overflow-y: auto;
    }

    &_doc {
      @media (max-height: $xxxl) {
        max-height: 80px;
      }
    }
  }

  &__item {
    position: relative;
    width: 20%;
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px;

    @media screen and (max-width: 850px) and (orientation: landscape) {
      width: 30%;
    }

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  &__img {
    width: 100px;
    height: auto;
    display: block;

    @media (max-height: $sm), (max-width: $sm) {
      width: 200px;
    }
  }

  .icon-cross {
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &__info {
    padding-left: 5px;
    padding-right: 8px;
    height: 24px;
    width: 134px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    margin-bottom: 5px;
    background-color: $text-disabled;
    border-radius: 24px;
    cursor: pointer;

    i {
      margin-right: 4px;
      margin-left: 4px;
      color: $text-light;
      font-size: 14px;
      cursor: pointer;
    }
  }

  &__name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $text-light;
  }
  &__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    margin-bottom: 1px;
    margin-left: auto;

    .icon-cross {
      margin-right: 0;
    }
  }
}
</style>
